import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateTax,
  getCartCount,
  getCartProducts,
  getSubTotal,
  getTotalAmount,
  removeCartItem,
} from "../Features/useCartSlice";
import { Endpoint } from "../Events/Endpoint";
import { Client } from "../api/Client";
import StringUtils from "../Utility/StringUtils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isEmptyArray } from "../Utility/Utility";

function CartCheckout() {
  const [successStatus, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errMessage, setError] = useState("");
  const [paymentData, setPaymentData] = useState([]);
  const [selectedPayment, setPaymentValue] = useState("");

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const { cartItems, subAmount, tax, totalAmount } = useSelector(
    (state) => state.cart
  );

  const handleRadioChange = (event) => {
    setPaymentValue(event.target.value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getPaymentDetails();
  }, []);

  function getPaymentDetails() {
    const url = Endpoint.PAYMENT_DETAILS;
    Client.getWithLoader(
      url,
      (response) => {
        if (!isEmptyArray(response.data)) {
          if (response.data.status) {
            setPaymentData(response.data.data);
          }
        }
      },
      (error) => {
        setPaymentData([]);
      }
    );
  }

  useEffect(() => {
    dispatch(getCartProducts());
    dispatch(getSubTotal());
    dispatch(getCartCount());
    dispatch(calculateTax());
    dispatch(getTotalAmount());
  }, [dispatch]);

  const onSubmit = (inputJSON) => {
    let PaymentMethod = inputJSON.PaymentMethod;

    let line_items = [];
    cartItems.map((product, index) => {
      line_items.push({
        product_id: product.id,
        quantity: product.quantity,
        price: product.price,
        product_name: product.name,
      });
    });

    inputJSON.line_items = line_items;
    inputJSON.price = totalAmount;

    if (PaymentMethod == "Paypal") {
      /*  navigate({
        pathname: '/checkout/paypal',
        state: inputJSON
      });  */

      console.log("inputJSON->:", inputJSON);

      navigate("/checkout/paypal", { state: inputJSON });
    } else {
      let url = Endpoint.SHOP_PLACE_ORDER;
      Client.postWithLoader(
        url,
        inputJSON,
        true,
        (response) => {
          if (StringUtils.isNotEmpty(response.data.status)) {
            if (response.data.status == true) {
              setSuccess(true);
              setSuccessMessage(response.data.message);

              cartItems.map((product, index) => {
                dispatch(removeCartItem(product.id));
                dispatch(getSubTotal());
                dispatch(getCartCount());
                dispatch(calculateTax());
                dispatch(getTotalAmount());
              });
            } else {
              setError(response.data.message);
              setTimeout(() => {
                setError("");
              }, 3000);
            }
          }
        },
        (error) => {
          setSuccess(false);
        }
      );
    }
  };

  return (
    <>
      <div className="mt70 sm-mt0">
        <section style={{ backgroundColor: "#202124" }}>
          <div className="text-center">
            <h1>Checkout</h1>
          </div>
        </section>

        <section className="pt-5 pb-5 de_light">
          <div className="container">
            <div className="row">
              {successStatus ? (
                <>
                  <h3 className="col-sm-12 text-center success">
                    {successMessage}
                  </h3>
                </>
              ) : (
                <>
                  <form
                    className="needs-validation"
                    name="contactForm"
                    id="contact_form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6 order-md-1">
                          <h4 className="mb-3">Billing address</h4>

                          <div className="row">
                            <div className="col-md-6 mb-3  pl-0 ">
                              <label htmlFor="firstName">First name</label>

                              <input
                                type="text"
                                name="firstName"
                                id="firstName"
                                className={
                                  "form-control " +
                                  (errors.Name && "error_input")
                                }
                                placeholder="Your first name"
                                {...register("FirstName", {
                                  required: true,
                                  maxLength: 20,
                                })}
                              />
                              {errors.FirstName &&
                                errors.FirstName.type === "required" && (
                                  <span className="text-danger" role="alert">
                                    This is required
                                  </span>
                                )}
                              {errors.FirstName &&
                                errors.FirstName.type === "maxLength" && (
                                  <span className="text-danger" role="alert">
                                    Max length exceeded
                                  </span>
                                )}
                            </div>

                            <div className="col-md-6 mb-3  pr-0">
                              <label htmlFor="LastName">Last name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="LastName"
                                placeholder="Your last name"
                                {...register("LastName", {
                                  required: true,
                                  maxLength: 20,
                                })}
                              />
                              {errors.LastName &&
                                errors.LastName.type === "required" && (
                                  <span className="text-danger" role="alert">
                                    This is required
                                  </span>
                                )}
                              {errors.LastName &&
                                errors.LastName.type === "maxLength" && (
                                  <span className="text-danger" role="alert">
                                    Max length exceeded
                                  </span>
                                )}
                            </div>
                          </div>

                          <div className="mb-3">
                            <label htmlFor="email">Email </label>
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder="you@example.com"
                              {...register("Email", {
                                required: true,
                                pattern:
                                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              })}
                            />

                            {errors.Email &&
                              errors.Email.type === "required" && (
                                <span className="text-danger" role="alert">
                                  This is required
                                </span>
                              )}
                            {errors.Email &&
                              errors.Email.type === "pattern" && (
                                <span className="text-danger" role="alert">
                                  Email id is not valid.
                                </span>
                              )}
                          </div>

                          <div className="mb-3">
                            <label htmlFor="address">Address</label>
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              placeholder="1234 Main St"
                              {...register("Address", {
                                required: true,
                                maxLength: 300,
                                minLength: 2,
                              })}
                            />
                            {errors.Address &&
                              errors.Address.type === "required" && (
                                <span className="text-danger" role="alert">
                                  This is required
                                </span>
                              )}
                            {errors.Address &&
                              errors.Address.type === "minLength" && (
                                <span className="text-danger" role="alert">
                                  Address should be atleast 2 character.
                                </span>
                              )}
                            {errors.Address &&
                              errors.Address.type === "maxLength" && (
                                <span className="text-danger" role="alert">
                                  Address should not be more than 300 character.
                                </span>
                              )}
                          </div>

                          <hr className="mb-4" />

                          <h4 className="mb-3">Payment</h4>

                          <div className="d-block my-3">
                            <div className="custom-control custom-radio">
                              <input
                                id="LocalJuice"
                                type="radio"
                                className="custom-control-input"
                                value="LocalJuice"
                                {...register("PaymentMethod", {
                                  required: true,
                                })}
                                onChange={handleRadioChange}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="LocalJuice"
                              >
                                Local - Juice
                              </label>
                              {selectedPayment == "LocalJuice" && (
                                <p>{paymentData.LocalJuice}</p>
                              )}
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                id="Blink"
                                type="radio"
                                className="custom-control-input"
                                value="Blink"
                                {...register("PaymentMethod", {
                                  required: true,
                                })}
                                onChange={handleRadioChange}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="Blink"
                              >
                                Blink
                              </label>
                              {selectedPayment == "Blink" && (
                                <p>{paymentData.Blink}</p>
                              )}
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                id="BankTransfer"
                                type="radio"
                                className="custom-control-input"
                                value="BankTransfer"
                                {...register("PaymentMethod", {
                                  required: true,
                                })}
                                onChange={handleRadioChange}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="BankTransfer"
                              >
                                Bank Transfer
                              </label>
                              {selectedPayment == "BankTransfer" && (
                                <p>{paymentData.BankTransfer}</p>
                              )}
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                id="paypal"
                                type="radio"
                                className="custom-control-input"
                                value={"Paypal"}
                                {...register("PaymentMethod", {
                                  required: true,
                                })}
                                onChange={handleRadioChange}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="paypal"
                              >
                                Paypal
                              </label>
                            </div>
                            {errors.PaymentMethod &&
                              errors.PaymentMethod.type === "required" && (
                                <span
                                  className="text-danger mt-2 clearfix"
                                  role="alert"
                                >
                                  Please select payment method
                                </span>
                              )}
                          </div>

                          <hr className="mb-4" />

                          <div className="d-grid mt-3">
                            <button
                              className="btn btn-primary mr-3  "
                              type="submit"
                            >
                              Continue to checkout
                            </button>

                            <Link
                              to="/shop"
                              className="btn btn-danger  text-white"
                            >
                              Continue Shopping
                            </Link>
                          </div>

                          <div className="col-md-12">
                            {errMessage && (
                              <div id="mail_fail" className="error">
                                {errMessage}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 order-md-2 mb-4">
                          <h4 className="mb-3">Shipping address</h4>

                          <div className="row">
                            <div className="col-md-6 mb-3  pl-0 ">
                              <label htmlFor="sfirstName">First name</label>

                              <input
                                type="text"
                                name="sfirstName"
                                id="sfirstName"
                                className={
                                  "form-control " +
                                  (errors.Name && "error_input")
                                }
                                placeholder="Your first name"
                                {...register("sFirstName", {
                                  required: true,
                                  maxLength: 20,
                                })}
                              />
                              {errors.sFirstName &&
                                errors.sFirstName.type === "required" && (
                                  <span className="text-danger" role="alert">
                                    This is required
                                  </span>
                                )}
                              {errors.sFirstName &&
                                errors.sFirstName.type === "maxLength" && (
                                  <span className="text-danger" role="alert">
                                    Max length exceeded
                                  </span>
                                )}
                            </div>

                            <div className="col-md-6 mb-3  pr-0">
                              <label htmlFor="sLastName">Last name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="sLastName"
                                placeholder="Your last name"
                                {...register("sLastName", {
                                  required: true,
                                  maxLength: 20,
                                })}
                              />
                              {errors.sLastName &&
                                errors.sLastName.type === "required" && (
                                  <span className="text-danger" role="alert">
                                    This is required
                                  </span>
                                )}
                              {errors.sLastName &&
                                errors.sLastName.type === "maxLength" && (
                                  <span className="text-danger" role="alert">
                                    Max length exceeded
                                  </span>
                                )}
                            </div>
                          </div>

                          <div className="mb-3">
                            <label htmlFor="semail">Email </label>
                            <input
                              type="email"
                              className="form-control"
                              id="semail"
                              placeholder="you@example.com"
                              {...register("sEmail", {
                                required: true,
                                pattern:
                                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              })}
                            />

                            {errors.sEmail &&
                              errors.sEmail.type === "required" && (
                                <span className="text-danger" role="alert">
                                  This is required
                                </span>
                              )}
                            {errors.sEmail &&
                              errors.sEmail.type === "pattern" && (
                                <span className="text-danger" role="alert">
                                  Email id is not valid.
                                </span>
                              )}
                          </div>

                          <div className="mb-3">
                            <label htmlFor="saddress">Address</label>
                            <input
                              type="text"
                              className="form-control"
                              id="saddress"
                              placeholder="1234 Main St"
                              {...register("sAddress", {
                                required: true,
                                maxLength: 300,
                                minLength: 2,
                              })}
                            />
                            {errors.sAddress &&
                              errors.sAddress.type === "required" && (
                                <span className="text-danger" role="alert">
                                  This is required
                                </span>
                              )}
                            {errors.sAddress &&
                              errors.sAddress.type === "minLength" && (
                                <span className="text-danger" role="alert">
                                  Address should be atleast 2 character.
                                </span>
                              )}
                            {errors.sAddress &&
                              errors.sAddress.type === "maxLength" && (
                                <span className="text-danger" role="alert">
                                  Address should not be more than 300 character.
                                </span>
                              )}
                          </div>

                          <div className="">
                            <div className="">
                              <div className="">
                                <h4 className="d-flex justify-content-between align-items-center mb-3">
                                  <span className="text-muted"></span>
                                </h4>

                                <div className="  ">
                                  <div className="row mb-4">
                                    <div className="col-sm-12 ">
                                      <h5 className="mb-3">Your order</h5>

                                      <table className="table table-bordered">
                                        <thead>
                                          <tr>
                                            <th colSpan={2}>Product</th>
                                            <th className="col-sm-3 col-md-3 ">
                                              Subtotal
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {cartItems.map((product, index) => {
                                            return (
                                              <tr key={product.id}>
                                                <td className="col-sm-1 col-md-1 ">
                                                  <img
                                                    className="media-object"
                                                    style={{ width: "90px" }}
                                                    src={
                                                      process.env
                                                        .REACT_APP_IMAGE_URL +
                                                      "/" +
                                                      product.image
                                                    }
                                                    alt={product.name}
                                                  />
                                                </td>
                                                <td className="col-sm-3 col-md-3 ">
                                                  {product.name} *{" "}
                                                  {product.quantity}
                                                </td>
                                                <td className="col-sm-3 col-md-3 ">
                                                  <strong>
                                                    {process.env
                                                      .REACT_APP_PRICE_SYMBOL +
                                                      " " +
                                                      parseFloat(
                                                        product.price *
                                                          product.quantity
                                                      ).toFixed(2)}
                                                  </strong>
                                                </td>
                                              </tr>
                                            );
                                          })}

                                          <tr>
                                            <td
                                              colSpan={2}
                                              className={"text-right"}
                                            >
                                              Subtotal
                                            </td>
                                            <td>
                                              {process.env
                                                .REACT_APP_PRICE_SYMBOL +
                                                " " +
                                                parseFloat(subAmount).toFixed(
                                                  2
                                                )}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              colSpan={2}
                                              className={"text-right"}
                                            >
                                              Total Amount
                                            </td>
                                            <td>
                                              {process.env
                                                .REACT_APP_PRICE_SYMBOL +
                                                " " +
                                                parseFloat(totalAmount).toFixed(
                                                  2
                                                )}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default CartCheckout;
