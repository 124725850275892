import React, { useEffect, useState } from 'react'
import { Client } from '../../api/Client';
import { Endpoint } from '../../Events/Endpoint';
import { isEmptyArray } from '../../Utility/Utility';

function TeamListing() {

  const [teamData, setTeam] = useState([]);


  useEffect(() => {
    getTeamList();
  }, []) 

  function getTeamList() {
		const url = Endpoint.TEAM_LIST;;
		Client.getWithLoader(
		  url,
		  (response) => { 
			if (!isEmptyArray(response.data)) {
			  if (response.data.status) {
          setTeam(response.data.data);
			  }
			}
		  },
		  (error) => {
        setTeam([]);
		  }
		);
	  }

  return (
    <>
    
     
    <div className="row no-gutters"> 


    {!isEmptyArray(teamData) && ( 
		teamData.map((data, key) => (
              <div key={key} className="col-lg-3 col-md-6">
                
                <div className="de-team-list">
                  <div className="team-pic">
                    <img
                      src={process.env.REACT_APP_IMAGE_URL + "/" + data.image}
                      className="img-responsive"
                      alt=""
                    />
                  </div>
                  <div className="team-desc">
                    <h3>{data.name+" "+data.surname}</h3>
                    <p className="lead">{data.designation}</p>
                    <div className="small-border"></div>
                    <p> 
                    <strong>Phone Number</strong>: {data.mobile} <br/> 
                    <strong>Email Address</strong>: {data.email} <br/>
                    <strong>Aptitude</strong>: {data.aptitude} <br/>



                    </p>

                     
                  </div>
                </div>
        
              </div>

    ))

    )}

                


              <div className="clearfix"></div>
            </div>
    
    </>
  )
}
export default TeamListing;