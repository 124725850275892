import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Client } from "../api/Client";
import GalleryListing from "../components/gallery/GalleryListing";
import { Endpoint } from "../Events/Endpoint";
import { isEmptyArray, isNotEmpty } from "../Utility/Utility";

function Gallery() {

  const [pageData, setPageData] = useState([]);
  const [headerBgImage, setHeaderBgImage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getPageData();  
  }, []); 


  function getPageData() {
    const url = Endpoint.PAGES_DATA+'/gallery';
    Client.getWithLoader(
      url,
      (response) => {
         
        if (!isEmptyArray(response.data)) {
          if (response.data.status) { 
            setPageData(response.data.data); 

            isNotEmpty(response.data.data.header_background_image) && ( setHeaderBgImage(process.env.REACT_APP_IMAGE_URL + "/" +response.data.data.header_background_image) );

          }
        }
      },
      (error) => {
        setPageData([]);
        setHeaderBgImage("");
      }
    );
  }


  return (
    <>
      <div className="page-projects">

        <section
          id="subheader"
          style={{
            background:
              'url("'+headerBgImage+'") 0% 0px / cover',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>{(pageData) ? pageData.header_title : 'About Us'}</h1>
                <ul className="crumb">
                  <li><Link to="/" >Home</Link></li>
                  <li className="sep">/</li>
                  <li>{(pageData) ? pageData.title : 'About Us'}</li>
                </ul>
              </div>
            </div>
          </div>
        </section>


            
           
<section
  id="section-portfolio"
  className="no-top no-bottom"
  aria-label="section-portfolio"
>
 

	 <GalleryListing />
 
 
 
</section>    


      </div>
    </>
  );
}
export default Gallery;
