import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Client } from "../../api/Client";
import { Endpoint } from "../../Events/Endpoint";
import { isEmptyArray } from "../../Utility/Utility";
import { useNavigate, useParams } from "react-router-dom";

function HomeOnlineCourses() {
  const [coursesData, setCourses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getCourse();
  }, []);

  function getCourse() {
    const url = Endpoint.ONLINE_COURSE_LIST;
    Client.getWithLoader(
      url,
      (response) => {
        if (!isEmptyArray(response.data)) {
          if (response.data.status) {
            setCourses(response.data.data);
          }
        }
      },
      (error) => {
        setCourses([]);
      }
    );
  }

  return (
    <>
      <div id="content" className="no-top no-bottom">
        <h1 style={{ color: "#fff" }}>Online COURSES</h1>
        <section id="pricing-table" style={{ backgroundColor: "#111" }}>
          <div className="item pricing">
            <div className="container">
              <div className="row">
                {!isEmptyArray(coursesData) &&
                  coursesData.map((data, key) => (
                    <div key={key} className="col-sm-4">
                      <div className="pricing-s1 light mb30">
                        <div className="top">
                          <h2>{data.title}</h2>
                          <p className="price">
                            <span className="currency">
                              {process.env.REACT_APP_PRICE_SYMBOL}
                            </span>{" "}
                            <b>{data.price}</b>
                          </p>
                        </div>
                        <div className="bottom">
                          <p className="box-bg-1">{data.description_limit}</p>

                          <div className="container">
                            
                              <button type="button" className="btn  btn-lg"  onClick={()=>{

navigate('/courses',{state:data});

}} >
                                View details
                              </button>
                             
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default HomeOnlineCourses;
