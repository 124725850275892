import React, { useEffect, useState } from "react";
import { Client } from "../api/Client";
import OfflineCourses from "../components/courses/OfflineCourses";
import OnlineCourses from "../components/courses/OnlineCourses";
import { Endpoint } from "../Events/Endpoint";
import { isEmptyArray, isNotEmpty } from "../Utility/Utility";
import { useLocation, useNavigate } from "react-router-dom";

function Courses() {
  const location = useLocation();
  const [pageData, setPageData] = useState([]);
  const [headerBgImage, setHeaderBgImage] = useState("");
  const [isOpenVideo, setOpenVideo] = useState(false);
  const [propsData, setPropsData] = useState(
    location.state ? location.state : []
  );

  useEffect(() => {
    if (propsData) {
      console.log("id: ", propsData.id);

      if (!isEmptyArray(propsData)) {
        setTimeout(() => {
          positionToCourses();
        }, 1500);
        setTimeout(() => {
          positionToCourses();
        }, 600);

      } else {
        window.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, 0);
    }

    getPageData();
  }, []);

  function positionToCourses() {
    /* const targetElement = document.getElementById("online-courses"); */
    const targetElement = document.getElementById("online-courses-tab");
    const targetOffsetTop = targetElement.offsetTop;

    console.log("targetOffsetTop  ", targetOffsetTop);

    window.scrollTo({
      top: targetOffsetTop,
      behavior: "smooth",
    });
  }

  function getPageData() {
    const url = Endpoint.PAGES_DATA + "/courses";
    Client.getWithLoader(
      url,
      (response) => {
        if (!isEmptyArray(response.data)) {
          if (response.data.status) {
            setPageData(response.data.data);

            isNotEmpty(response.data.data.header_background_image) &&
              setHeaderBgImage(
                process.env.REACT_APP_IMAGE_URL +
                  "/" +
                  response.data.data.header_background_image
              );
          }
        }
      },
      (error) => {
        setPageData([]);
        setHeaderBgImage("");
      }
    );
  }

  const togglePopup = () => {
    setOpenVideo(!isOpenVideo);
  };

  return (
    <>
      <section
        id="section-welcome-8"
        style={{
          background: 'url("' + headerBgImage + '") 0% 0px / cover',
        }}
      >
        <div className="spacer-single"></div>
        <div className="container">
          <div className="row align-items-center mt-5 ">
            <div className="col-md-5">
              <h2>{pageData ? pageData.header_title : "COURSES"}</h2>
              {pageData && (
                <p className="lead">{pageData.header_short_description}</p>
              )}
              <div className="spacer-half"></div>
            </div>

            {pageData.header_video_url && (
              <>
                <div className="col-md-6 offset-md-1">
                  <figure className="picframe invert transparent shadow-soft rounded">
                    <span className="v-center">
                      <span>
                        <a
                          id="play-video"
                          className="video-play-button popup-youtube"
                          onClick={togglePopup}
                        >
                          <span></span>
                        </a>
                      </span>
                    </span>
                    <img
                      src={
                        "" +
                        process.env.REACT_APP_IMAGE_URL +
                        "/" +
                        pageData.header_video_background_image +
                        ""
                      }
                      className="img-fullwidth"
                      alt=""
                    />
                  </figure>
                </div>
              </>
            )}
          </div>
        </div>
      </section>

      {isOpenVideo && (
        <>
          <div
            className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-fade mfp-ready popup-video-1"
            tabIndex="-1"
          >
            <div className="mfp-container mfp-iframe-holder">
              <div className="mfp-content">
                <div className="mfp-iframe-scaler">
                  <button
                    title="Close (Esc)"
                    type="button"
                    className="mfp-close"
                    onClick={togglePopup}
                  >
                    ×
                  </button>
                  <iframe
                    className="mfp-iframe"
                    src={pageData.header_video_url}
                    frameBorder="0"
                    allowFullScreen=""
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <OfflineCourses />

      <OnlineCourses dataProp={propsData} />
    </>
  );
}
export default Courses;
