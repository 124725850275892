import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateTax,
  decrement,
  getCartCount,
  getCartProducts,
  getSubTotal,
  getTotalAmount,
  increment,
  removeCartItem,
} from "../Features/useCartSlice";
import { Link } from "react-router-dom";
import { isEmptyArray, isNotEmpty } from "../Utility/Utility";
import { Endpoint } from "../Events/Endpoint";
import { Client } from "../api/Client";

function Cart() {
  const [headerBgImage, setHeaderBgImage] = useState("");

  const dispatch = useDispatch();
  const { cartItems, subAmount, totalAmount } = useSelector(
    (state) => state.cart
  );

  useEffect(() => {
    dispatch(getCartProducts());
    dispatch(getSubTotal());
    dispatch(getCartCount());
    dispatch(calculateTax());
    dispatch(getTotalAmount());
  }, [dispatch]);


  useEffect(() => {
    window.scrollTo(0, 0);
    getPageData();  
    
  }, []); 

  function getPageData() {
    const url = Endpoint.PAGES_DATA+'/shop';
    Client.getWithLoader(
      url,
      (response) => {
         
        if (!isEmptyArray(response.data)) {
          if (response.data.status) { 
           

            isNotEmpty(response.data.data.header_background_image) && ( setHeaderBgImage(process.env.REACT_APP_IMAGE_URL + "/" +response.data.data.header_background_image) );

          }
        }
      },
      (error) => {
       
        setHeaderBgImage("");
      }
    );
  }

  return (
    <>
      <div className="de_light">
        <section
          id="subheader"
          style={{
            background: 'url("' + headerBgImage + '") 0% 0px / cover',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>Cart</h1>
                <ul className="crumb">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="sep">/</li>
                  <li>Cart</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <div id="content" className="no-top">
          <div className="container">
            <div className="row">
              {!isEmptyArray(cartItems) ? (
                <>
                  <table className="table table-hover mb-3 cart-table">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th className="text-center">Quantity</th>
                        <th className="text-center">Price</th>
                        <th className="text-center">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartItems.map((product, index) => {
                        return (
                          <tr key={product.id}>
                            <td className="col-md-4">
                              <div className="media">
                                <a className="thumbnail pull-left" href="/#">
                                  <img
                                    className="media-object"
                                    src={process.env.REACT_APP_IMAGE_URL + '/' +product.image}
                                    alt={product.name}
                                  />
                                </a>
                              </div>
                            </td>

                            <td className="col-sm-1 col-md-2 text-center">
                              <div className="cart-quantity">
                                <button
                                  className="btn btn-sm inline-block"
                                  onClick={() => {
                                    dispatch(decrement(product.id));
                                    dispatch(getSubTotal());
                                    dispatch(getCartCount());
                                    dispatch(calculateTax());
                                    dispatch(getTotalAmount());
                                  }}
                                >
                                  <i className="fa fa-minus"></i>
                                </button>
                                <span className="text-center inline-block">
                                  {product.quantity}
                                </span>
                                <button
                                  className="btn btn-sm inline-block"
                                  onClick={() => {
                                    dispatch(increment(product.id));
                                    dispatch(getSubTotal());
                                    dispatch(getCartCount());
                                    dispatch(calculateTax());
                                    dispatch(getTotalAmount());
                                  }}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            </td>
                            <td className="col-sm-1 col-md-1 text-center">
                              <strong>
                                {" "}
                                {process.env.REACT_APP_PRICE_SYMBOL +
                                  " " +
                                  product.price}
                              </strong>
                            </td>
                            <td className="col-sm-1 col-md-1 text-center">
                              <strong>
                                {process.env.REACT_APP_PRICE_SYMBOL +
                                  " " +
                                  parseFloat(
                                    product.price * product.quantity
                                  ).toFixed(2)}
                              </strong>
                            </td>
                            <td className="col-sm-1 col-md-1">
                              <button
                                type="button"
                                onClick={() => {
                                  dispatch(removeCartItem(product.id));
                                  dispatch(getSubTotal());
                                  dispatch(getCartCount());
                                  dispatch(calculateTax());
                                  dispatch(getTotalAmount());
                                }}
                                className="btn btn-sm btn-outline-danger"
                              >
                                <i className="fa fa-trash-o"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className="col-md-12 cart">
                    <div className="col-md-6 p-4 offset-md-6 border alert alert-secondary">
                      <div className="d-flex gst">
                        <div className="flex-grow-1">Subtotal</div>
                        {process.env.REACT_APP_PRICE_SYMBOL +
                          " " +
                          parseFloat(subAmount).toFixed(2)}
                      </div>
                      <hr />
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <strong>Total Amount</strong>
                        </div>
                        <div>
                          <strong>
                            {process.env.REACT_APP_PRICE_SYMBOL +
                              " " +
                              parseFloat(totalAmount).toFixed(2)}
                          </strong>
                        </div>
                      </div>
                      <div className="d-grid mt-3">
                        <Link
                          type="button"
                          className="btn btn-dark text-white"
                          to="/checkout"
                        >
                          Proceed to Checkout
                        </Link>

                        <Link to="/shop" className="btn btn-danger pull-right text-white">
                          Continue Shopping
                        </Link>


                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                <div className="col-md-12 cart">
                  <h2 className="mt-4 h2 text-center mb-3 d-block">Your cart is empty</h2>
                  <div className="text-center">
                    <Link to="/shop" className="btn btn-danger">
                      Shop now
                    </Link>
                  </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Cart;
