import React from "react";
import { Link } from "react-router-dom";

function ShopSidebar() {
  return (
    <>
      <div id="sidebar" className="col-md-3">

        {/* <div className="widget widget_search">
          <input
            type="text"
            name="search"
            id="search"
            className="form-control"
            placeholder="search product"
          />
          <button id="btn-search" type="submit"></button>
          <div className="clearfix"></div>
        </div>
         */}

        <div className="widget widget_category">
          <h4>Product Category</h4>
          <ul>
            <li>
              <Link to="/shop/women">Women</Link>
            </li>
            <li>
              <Link to="/shop/men">Men </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
export default ShopSidebar;
