import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Client } from "../api/Client";
import { Endpoint } from "../Events/Endpoint";
import StringUtils from "../Utility/StringUtils";
import { isEmptyArray } from "../Utility/Utility";

function CourseCheckout() {
  const { id, vid } = useParams();
  const [coursesData, setCourses] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [selectedPayment, setPaymentValue] = useState('');

  const [successStatus, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errMessage, setError] = useState("");
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getCourse();
    getPaymentDetails();
  }, []);

  const handleRadioChange = (event) => {
    setPaymentValue(event.target.value);
  };

  function getCourse() {
    const url = Endpoint.ONLINE_COURSE_LIST + `/${id}`;
    Client.getWithLoader(
      url,
      (response) => {
        if (!isEmptyArray(response.data)) {
          if (response.data.status) {
            setCourses(response.data.data);
          }
        }
      },
      (error) => {
        setCourses([]);
      }
    );
  }

  function getPaymentDetails() {
    const url = Endpoint.PAYMENT_DETAILS;
    Client.getWithLoader(
      url,
      (response) => {
        if (!isEmptyArray(response.data)) {
          if (response.data.status) {
            setPaymentData(response.data.data);
          }
        }
      },
      (error) => {
        setPaymentData([]);
      }
    );
  }

  const onSubmit = (inputJSON) => {
    inputJSON.course = id;
    inputJSON.vid = vid;
    
    console.log(inputJSON);

    let PaymentMethod = inputJSON.PaymentMethod;

    if (PaymentMethod == "Paypal") {
      inputJSON.price = coursesData.price;

      console.log("inputJSON->:", inputJSON);

      navigate("/checkout/course/paypal", { state: inputJSON });
    } else {
      let url = Endpoint.COURSE_PLACE_ORDER;
      Client.postWithLoader(
        url,
        inputJSON,
        true,
        (response) => {
          if (StringUtils.isNotEmpty(response.data.status)) {
            if (response.data.status == true) {
              setSuccess(true);
              setSuccessMessage(response.data.message);
            } else {
              setError(response.data.message);
              setTimeout(() => {
                setError("");
              }, 3000);
            }
          }
        },
        (error) => {
          setSuccess(false);
        }
      );
    }
  };

  return (
    <>
      <div className="mt70 sm-mt0">
        <section style={{ backgroundColor: "#202124" }}>
          <div className="text-center">
            <h1>Checkout</h1>
          </div>
        </section>

        <section className="pt-5 pb-5 de_light">
          <div className="container">
            <div className="row">
              {successStatus ? (
                <>
                  <h3 className="col-sm-12 text-center success">
                    {successMessage}
                  </h3>
                </>
              ) : (
                <>
                  <div className="col-md-4 order-md-2 mb-4">
                    <h4 className="d-flex justify-content-between align-items-center mb-3">
                      <span className="text-muted">Payment</span>
                    </h4>
                    <ul className="list-group mb-3">
                      <li className="list-group-item d-flex justify-content-between">
                        <span>Total (MUR)</span>
                        <strong>
                          {process.env.REACT_APP_PRICE_SYMBOL +
                            " " +
                            coursesData.price}
                        </strong>
                      </li>
                    </ul>
                  </div>

                  <div className="col-md-8 order-md-1">
                    <h4 className="mb-3">Billing address</h4>
                    <form
                      className="needs-validation"
                      name="contactForm"
                      id="contact_form"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="row">
                        <div className="col-md-6 mb-3  pl-0 ">
                          <label htmlFor="firstName">First name</label>

                          <input
                            type="text"
                            name="firstName"
                            id="firstName"
                            className={
                              "form-control " + (errors.Name && "error_input")
                            }
                            placeholder="Your first name"
                            {...register("FirstName", {
                              required: true,
                              maxLength: 20,
                            })}
                          />
                          {errors.FirstName &&
                            errors.FirstName.type === "required" && (
                              <span className="text-danger" role="alert">
                                This is required
                              </span>
                            )}
                          {errors.FirstName &&
                            errors.FirstName.type === "maxLength" && (
                              <span className="text-danger" role="alert">
                                Max length exceeded
                              </span>
                            )}
                        </div>

                        <div className="col-md-6 mb-3  pr-0">
                          <label htmlFor="LastName">Last name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="LastName"
                            placeholder="Your last name"
                            {...register("LastName", {
                              required: true,
                              maxLength: 20,
                            })}
                          />
                          {errors.LastName &&
                            errors.LastName.type === "required" && (
                              <span className="text-danger" role="alert">
                                This is required
                              </span>
                            )}
                          {errors.LastName &&
                            errors.LastName.type === "maxLength" && (
                              <span className="text-danger" role="alert">
                                Max length exceeded
                              </span>
                            )}
                        </div>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="email">Email </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="you@example.com"
                          {...register("Email", {
                            required: true,
                            pattern:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          })}
                        />

                        {errors.Email && errors.Email.type === "required" && (
                          <span className="text-danger" role="alert">
                            This is required
                          </span>
                        )}
                        {errors.Email && errors.Email.type === "pattern" && (
                          <span className="text-danger" role="alert">
                            Email id is not valid.
                          </span>
                        )}
                      </div>

                      <div className="mb-3">
                        <label htmlFor="address">Address</label>
                        <input
                          type="text"
                          className="form-control"
                          id="address"
                          placeholder="1234 Main St"
                          {...register("Address", {
                            required: true,
                            maxLength: 300,
                            minLength: 10,
                          })}
                        />
                        {errors.Address &&
                          errors.Address.type === "required" && (
                            <span className="text-danger" role="alert">
                              This is required
                            </span>
                          )}
                        {errors.Address &&
                          errors.Address.type === "minLength" && (
                            <span className="text-danger" role="alert">
                              Address should be atleast 10 character.
                            </span>
                          )}
                        {errors.Address &&
                          errors.Address.type === "maxLength" && (
                            <span className="text-danger" role="alert">
                              Address should not be more than 300 character.
                            </span>
                          )}
                      </div>

                      <hr className="mb-4" />

                      <h4 className="mb-3">Payment</h4>

                      <div className="d-block my-3">
                        <div className="custom-control custom-radio">
                          <input
                            id="LocalJuice"
                            type="radio"
                            className="custom-control-input"
                            value="LocalJuice"
                            {...register("PaymentMethod", { required: true })}
                            onChange={handleRadioChange}

                          />
                          <label
                            className="custom-control-label"
                            htmlFor="LocalJuice"
                          >
                            Local - Juice
                          </label> 
                           {(selectedPayment=='LocalJuice') && (<p>{paymentData.LocalJuice}</p>) }     
                        </div>

                        <div className="custom-control custom-radio">
                          <input
                            id="Blink"
                            type="radio"
                            className="custom-control-input"
                            value="Blink"
                            {...register("PaymentMethod", { required: true })}
                            onChange={handleRadioChange}

                          />
                          <label
                            className="custom-control-label"
                            htmlFor="Blink"
                          >
                            Blink
                          </label>
                          {(selectedPayment=='Blink') && (<p>{paymentData.Blink}</p>) }
                        </div>

                        <div className="custom-control custom-radio">
                          <input
                            id="BankTransfer"
                            type="radio"
                            className="custom-control-input"
                            value="BankTransfer"
                            {...register("PaymentMethod", { required: true })}
                            onChange={handleRadioChange}

                          />
                          <label
                            className="custom-control-label"
                            htmlFor="BankTransfer"
                          >
                            Bank Transfer
                          </label>
                          {(selectedPayment=='BankTransfer') && (<p>{paymentData.BankTransfer}</p>) }
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            id="paypal"
                            type="radio"
                            className="custom-control-input"
                            value={"Paypal"}
                            {...register("PaymentMethod", { required: true })}
                            onChange={handleRadioChange}

                          />
                          <label
                            className="custom-control-label"
                            htmlFor="paypal"
                          >
                            Paypal
                          </label>
                        </div>
                        {errors.PaymentMethod &&
                          errors.PaymentMethod.type === "required" && (
                            <span
                              className="text-danger mt-2 clearfix"
                              role="alert"
                            >
                              Please select payment method
                            </span>
                          )}
                      </div>

                      <hr className="mb-4" />
                      <button
                        className="btn btn-primary btn-lg btn-block"
                        type="submit"
                      >
                        Continue to checkout
                      </button>

                      <div className="col-md-12">
                        {errMessage && (
                          <div id="mail_fail" className="error">
                            {errMessage}
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default CourseCheckout;
