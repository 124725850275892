import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmptyArray } from "../Utility/Utility";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Endpoint } from "../Events/Endpoint";
import { Client } from "../api/Client";
import StringUtils from "../Utility/StringUtils";

function CourseCheckoutPaypal() {
  const location = useLocation();
  const [propsData, setPropsData] = useState(
    location.state ? location.state : []
  );

  const [paypalTotalAmount, setPaypalTotalAmount] = useState(parseFloat(propsData.price/45.4).toFixed(2));
  const [totalAmount, setTotalAmount] = useState(propsData.price); 

  const [successStatus, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errMessage, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (propsData) {
      console.log("propsData: ", propsData);

      if (isEmptyArray(propsData)) {
        navigate("/");
      } else { 

      }
    }
  }, []);

  

  return (
    <>
      <div className="mt70 sm-mt0">
        <section style={{ backgroundColor: "#202124" }}>
          <div className="text-center">
            <h1>Checkout</h1>
          </div>
        </section>

        <section className="pt-5 pb-5 de_light">
          <div className="container">
            <div className="row">
              {successStatus ? (
                <>
                  <h3 className="col-sm-12 text-center success">
                    {successMessage}
                  </h3>
                </>
              ) : (
                <>
                  <br />
                  <br />
                  <br />

                  <div className="col-md-7 order-md-1">

                  <div className="col-md-12 order-md-2 mb-4">
                    <h4 className="d-flex justify-content-between align-items-center mb-3">
                      <span className="text-muted">Payment</span>
                    </h4>
                    <ul className="list-group mb-3">
                      <li className="list-group-item d-flex justify-content-between">
                        <span>Total (MUR)</span>
                        <strong>
                          {process.env.REACT_APP_PRICE_SYMBOL + " " + totalAmount}

                            
                        </strong>
                      </li>
                    </ul>
                  </div>


     

                      {paypalTotalAmount && (
                    <PayPalScriptProvider
                      options={{
                        clientId:
                          "AXkCiPJXPwfa_kHFm4WtbGI3m_FOhJL4SMzCXiEHLyCwLIVtVpZM0YrSqrAfHM1CCCxq4qWOS5c_yZo0",
                        intent: "capture",
                        currency: "USD",
                      }}
                    >
                      <PayPalButtons
                        style={{ layout: "horizontal" }}
                        createOrder={(data, actions) => {
                          return actions.order.create({
                            purchase_units: [
                              {
                                amount: {
                                  value: paypalTotalAmount,

                                },
                              },
                            ],
                          });
                        }}
                        onApprove={(data, actions) => {
                          return actions.order
                            .capture()
                            .then((paypalResponseDetails) => {
                              let url = Endpoint.PAYPAL_COURSE_PLACE_ORDER;

                              /* 
                              const name = details.payer.name.given_name;  
                              let inputJSON = [];
                              inputJSON.paypalResponseDetails = details; 
                              */

                              paypalResponseDetails.inputJSON = propsData;

                              Client.postWithLoader(
                                url,
                                paypalResponseDetails,
                                true,
                                (response) => {
                                  if (
                                    StringUtils.isNotEmpty(response.data.status)
                                  ) {
                                    if (response.data.status == true) {
                                      setSuccess(true);
                                      setSuccessMessage(response.data.message);
                                    } else {
                                      setError(response.data.message);
                                      setTimeout(() => {
                                        setError("");
                                      }, 3000);
                                    }
                                  }
                                },
                                (error) => {
                                  setSuccess(false);
                                }
                              );
                            });
                        }}
                      />
                    </PayPalScriptProvider>
                    )}


                  </div>

                  

                </>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default CourseCheckoutPaypal;
