import logoImg from "./../assets/Logo/logo01.png";
import logoImg2 from "./../assets/Logo/logo02.png";
import React, { useEffect, useState } from "react";
import Navigation from "./Navigation";
import { Link, useLocation } from "react-router-dom";

function Header() {
  let location = useLocation();

  return (
    <>
      <header
        className={
          location.pathname == "/shop" || location.pathname.includes("shop") || location.pathname == "/cart"
            ? "header-bg header-light clone smaller"
            : ""
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div id="logo">
                <Link className="" to="/">
                  <img
                    className="logo"
                    src={
                      location.pathname == "/shop" ||
                      location.pathname.includes("shop") || 
                      location.pathname == "/cart"
                        ? logoImg2
                        : logoImg
                    }
                    width="130px"
                    alt=""
                  />
                </Link>
              </div>

              <span id="menu-btn"></span>

              <nav>
                <ul id="mainmenu" className="no-separator">
                  <Navigation /> 

                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
export default Header;
