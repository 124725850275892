import React, { useEffect, useState } from "react";
import { Client } from "../../api/Client";
import { Endpoint } from "../../Events/Endpoint";
import { isEmptyArray } from "../../Utility/Utility";
import ProductItem from "../shop/ProductItem";
import ProductItemHome from "../shop/ProductItemHome";

function HomeShop() {
	const [latestProduct, setLatestProduct] = useState([]);
  useEffect(() => {
    getProduct();
  }, []);

  function getProduct() {
    let url = Endpoint.GET_PRODUCT_LISTING + `?page=1&per_page=6`;
    Client.getWithLoader(
      url,
      (response) => {
        setLatestProduct(response.data.data);
      },
      (error) => {
        setLatestProduct([]);
      }
    );
  }

  return (
    <>
      {!isEmptyArray(latestProduct) && (
        <div id="content">
          <div className="container">
            <h1 style={{ color: "#fff" }}>SHOP</h1>
            <div
              className="row"
              style={{ justifyContent: "center", marginTop: "3rem" }}
            >
              <ul className="products row">

			  	  {latestProduct.map((value, key) => (
                    <ProductItemHome  data={value} key={key} />
                  ))} 
                

              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default HomeShop;
