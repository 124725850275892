import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import CourseCheckout from "./Pages/CourseCheckout";
import Courses from "./Pages/Courses";
import Gallery from "./Pages/Gallery";
import Home from "./Pages/Home";
import NotFound from "./Pages/NotFound";
import Shop from "./Pages/Shop";
import Team from "./Pages/Team";
import Cart from "./Pages/Cart";
import CartCheckout from "./Pages/CartCheckout";
import TestPage from "./Pages/TestPage";
import CartCheckoutPaypal from "./Pages/CartCheckoutPaypal";
import CourseCheckoutPaypal from "./Pages/CourseCheckoutPaypal";


function App() { 

  return (
    <>
      <BrowserRouter> 
        <Header />
        <div id="content" className={'no-bottom no-top'}>

        <Routes>        
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/courses" element={<Courses />} />

          <Route path="/shop" element={<Shop />} />	
          <Route path="/shop/:slug" element={<Shop />} />	
          
          <Route path="/team" element={<Team />} />	
          <Route path="/gallery" element={<Gallery />} />	
          <Route path="/contact" element={<Contact />} />	

          <Route path="/checkout/course/:id/:vid" element={<CourseCheckout />} />
          <Route path="/checkout/course/paypal" element={<CourseCheckoutPaypal />} />	

          <Route path="/checkout" element={<CartCheckout />} />	 
          <Route path="/checkout/paypal" element={<CartCheckoutPaypal />} />

          <Route path="cart" element={<Cart/>} />


          <Route path="test" element={<TestPage/>} />


          <Route path="*" element={<NotFound />} />


          
          
        </Routes>   

        <Footer /> 
        </div>

      </BrowserRouter>
    </>
  );
}

export default App;
