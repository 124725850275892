import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Client } from "../api/Client";
import { Endpoint } from "../Events/Endpoint";
import StringUtils from "../Utility/StringUtils";
import { isEmptyArray, isNotEmpty } from "../Utility/Utility";

function Contact() {

  const [contactList, setContactList] = useState([]);
  const [contactDetails, setContactDetails] = useState([]);

  const [successMessage, setSuccess] = useState(false);
  const [errMessage, setError] = useState('');

  const [pageData, setPageData] = useState([]);
  const [headerBgImage, setHeaderBgImage] = useState("");

    const { register,handleSubmit,formState: { errors },} = useForm();


    useEffect(() => {
      getContactList();
      getPageData();
      getContactDetails();  
      window.scrollTo(0, 0);
    }, []) 


    function getContactDetails() {
      const url = Endpoint.CONTACT_DETAILS;;
      Client.getWithLoader(
        url,
        (response) => { 
        if (!isEmptyArray(response.data)) {
          if (response.data.status) {

            console.log(response.data.data);

            setContactDetails(response.data.data);
          }
        }
        },
        (error) => {
          setContactDetails([]);
        }
      );
      }

    function getContactList() {
		const url = Endpoint.CONTACT_INFO_LIST;;
		Client.getWithLoader(
		  url,
		  (response) => { 
			if (!isEmptyArray(response.data)) {
			  if (response.data.status) {
          setContactList(response.data.data);
			  }
			}
		  },
		  (error) => {
        setContactList([]);
		  }
		);
	  }

    function getPageData() {
      const url = Endpoint.PAGES_DATA+'/contact_us';
      Client.getWithLoader(
        url,
        (response) => {
           
          if (!isEmptyArray(response.data)) {
            if (response.data.status) { 
              setPageData(response.data.data); 
  
              isNotEmpty(response.data.data.header_background_image) && ( setHeaderBgImage(process.env.REACT_APP_IMAGE_URL + "/" +response.data.data.header_background_image) );
  
            }
          }
        },
        (error) => {
          setPageData([]);
          setHeaderBgImage("");
        }
      );
    }


    const onSubmit = (inputJSON) => {
       

      let url = Endpoint.SUBMIT_CONTACT_FORM;  

      Client.postWithLoader(url, inputJSON, true, (response) => {
        
          console.log(response.data.status);

          if(StringUtils.isNotEmpty(response.data.status)){
            if(response.data.status ==  true){
              setSuccess(true);
            }else{
              setError(response.data.message);
              setTimeout(() => {
                setError("");
              }, 3000);

            }
          } 
        
        
        },
        (error) => {
            
        }
        ); 
    
    
    }

  return (
    <>
      <section
          id="subheader"
          style={{
            background:
              'url("'+headerBgImage+'") 0% 0px / cover',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>{(pageData) ? pageData.header_title : 'About Us'}</h1>
                <ul className="crumb">
                  <li><Link to="/" >Home</Link></li>
                  <li className="sep">/</li>
                  <li>{(pageData) ? pageData.title : 'About Us'}</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

      <div id="content" className="no-top">
        <div className="container">
          <div className="row">

          {!isEmptyArray(contactList) && ( 
		          contactList.map((data, key) => (    
            <div key={key} className="column">
              <div className="card">
                <div className="content">
                  <div className="front">
                    <img
                      className="profile"
                      width="100%"
                      src={process.env.REACT_APP_IMAGE_URL+'/'+data.image}
                      alt={data.name}
                    />
                    <h2>{data.heading}</h2>
                  </div>
                  <div className="back from-left">
                    <h2>{data.name}</h2>
                    <h3>{data.address}</h3>
                    <h3>{(data.phone) && data.phone}</h3>
                    <h3>{(data.mobile) && data.mobile}</h3>

                    <br />
                    <p className="des">{data.email}</p>
                  </div>
                </div>
              </div>
            </div>
                ))

                )}

         
          </div>
        </div>

        <div className="container" style={{marginTop: '5rem'}} >
          <div className="row">
            <div className="col-md-8">
              <h3>Send Us Message</h3>

              <form
                name="contactForm"
                id="contact_form"
              
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="row">

                {(!successMessage) && (<>

                  <div className="col-md-4">
                    
                    <div className="mb-2">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className={"form-control "+((errors.Name) && 'error_input') }
                        placeholder="Your Name"
                        {...register("Name", { required: true, maxLength: 20 })}
                      />
                      {errors.Name && errors.Name.type === "required" && (
                      <span className="text-danger" role="alert">This is required</span>
                      )}
                      {errors.Name && errors.Name.type === "maxLength" && (
                          <span className="text-danger" role="alert">Max length exceeded</span>
                      )}  
                      
                      
                    </div>

                    
                    <div className="mb-2">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        className={"form-control "+((errors.Email) && 'error_input') }
                        placeholder="Your Email"
                        {...register("Email",
                        {
                            required: true,
                            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        })}
                      />
                      {errors.Email && errors.Email.type === "required" && (
                      <span className="text-danger" role="alert">This is required</span>
                      )}
                      {errors.Email && errors.Email.type === "pattern" && (
                          <span className="text-danger" role="alert">Email id is not valid.</span>
                      )} 
                    </div>

                  
                    <div className="mb-2">
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        className={"form-control "+((errors.Phone) && 'error_input') }
                        placeholder="Your Phone"
                        {...register("Phone", { required: true, maxLength: 10, minLength: 10 })}
                      />
                      {errors.Phone && errors.Phone.type === "required" && (
                      <span className="text-danger" role="alert">This is required</span>
                      )}
                      {errors.Phone && errors.Phone.type === "minLength" && (
                          <span className="text-danger" role="alert">Phone number should be atleast 10 digit.</span>
                      )} 
                      {errors.Phone && errors.Phone.type === "maxLength" && (
                          <span className="text-danger" role="alert">Max length exceeded</span>
                      )} 
                    </div>
                  </div>
                  <div className="col-md-8">
                    
                    <div className="mb-2">
                      <textarea
                        name="message"
                        id="message"
                        className={"form-control "+((errors.Message) && 'error_input') }
                        placeholder="Your Message"
                        {...register("Message", { required: true, maxLength: 300, minLength: 30 })}
                      ></textarea>
                      {errors.Message && errors.Message.type === "required" && (
                        <span className="text-danger" role="alert">This is required</span>
                        )}
                        {errors.Message && errors.Message.type === "minLength" && (
                            <span className="text-danger" role="alert">Message should be atleast 30 character.</span>
                        )} 
                        {errors.Message && errors.Message.type === "maxLength" && (
                            <span className="text-danger" role="alert">Message should not be more than 300 character.</span>
                        )} 
                    </div>
                  </div>

                  <div className="col-md-12">
                    <p id="submit">
                      <input
                        type="submit"
                        id="send_message"
                        value="Submit Form"
                        className="btn btn-line"
                      />
                    </p> 
                  </div>
                  </>
                  )}



                  <div className="col-md-12">
                  {(successMessage) && (
                     <div id="mail_success" className="success">
                      Your message has been sent successfully.
                    </div>
                    )}

                  {(errMessage) && (
                    <div id="mail_fail" className="error">
                    {errMessage}
                  </div>
                  )}
                  </div>



                </div>
              </form>
            </div>

            <div id="sidebar" className="col-md-4">
              <div className="widget widget_text">
                <h3>Contact Info</h3>
                <address>
                  <span>{(contactDetails.Address) && contactDetails.Address}</span>
                  <span>
                    <strong>Company:</strong>&nbsp;&nbsp; {(contactDetails.SiteSlogan) && contactDetails.SiteSlogan}
                  </span>
                  <span>
                    <strong>Phone:</strong>{(contactDetails.PhoneNumber) && contactDetails.PhoneNumber}
                  </span> 
                  <span>
                    <strong>Email:</strong>
                    <a href="mailto:{(contactDetails.EmailAddress) && contactDetails.EmailAddress}">{(contactDetails.EmailAddress) && contactDetails.EmailAddress}</a>
                  </span>
                </address>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
export default Contact;
