import React from "react";
import { useDispatch } from "react-redux";
import { isEmptyArray } from "../../Utility/Utility";
import {
  addCartProduct,
  calculateTax,
  getCartCount,
  getSubTotal,
  getTotalAmount,
} from "../../Features/useCartSlice";
import { useNavigate } from "react-router-dom";

function ProductItemHome(props) {
  let data = props.data;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  let productObj = {
    id: "",
    product_name: "",
    price: "",
    image: "",
    slug: "",
  };

  const addToCart = (item) => {
    productObj = {
      id: item.id,
      name: item.product_name,
      price: item.price,
      image: !isEmptyArray(data.image) ? data.image : "",
      slug: item.slug,
    };

    dispatch(addCartProduct(productObj));
    dispatch(getCartCount());
    dispatch(getSubTotal());
    dispatch(calculateTax());
    dispatch(getTotalAmount());

    navigate("/shop");
  };

  return (
    <>
      <li className="col-md-4 product">
        <img
          src={"" + process.env.REACT_APP_IMAGE_URL + "/" + data.image + ""}
          className="img-responsive"
          alt=""
        />
        <h4>{data.product_name}</h4>
        <div className="price">
          {process.env.REACT_APP_PRICE_SYMBOL + " " + data.price}
        </div>
        <a
          className="btn btn-line"
          onClick={() => {
            addToCart(data);
          }}
        >
          Add To Cart
        </a>
      </li>
    </>
  );
}
export default ProductItemHome;
