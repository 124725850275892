import React, { useEffect, useState } from 'react'
import { Client } from '../../api/Client';
import { Endpoint } from '../../Events/Endpoint';
import { isEmptyArray } from '../../Utility/Utility';

function About() {

  const [aboutData, setAbout] = useState([]);

  useEffect(() => {
    getAboutData(); 
  }, []);


  function getAboutData() {
    
    const url = Endpoint.HOME_ABOUT_DATA;
    Client.getWithLoader(
      url,
      (response) => {
        /* console.log("response about", response.data.data); */
        if (!isEmptyArray(response.data)) {
          if (response.data.status) {
            setAbout(response.data.data);
          }
        }
      },
      (error) => {
        setAbout([]);
      }
    );

  }

  return (
    <>
    <section id="section-about">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3 text-center wow  fadeInUp">
              <h1 style={{ color: "#000" }}>{(aboutData.main_title) && aboutData.main_title }</h1>
              <br />
              <br />

              {(aboutData.main_description) &&              
                  (<h5 
                    style={{
                      color: "#838383",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  > 
                     {aboutData.main_description}
                  </h5>)
              }
               
              
              <div className="separator">
                <span>
                  <i className="fa fa-circle"></i>
                </span>
              </div>
              <div className="spacer-single"></div>
            </div>

            <div className="box" style={{ display: "flex", gap: "rem" }}>

              <div className="box1">

                {(aboutData.title1)  &&
                  (<div
                    className="wow fadeInLeft"
                    style={{ display: "flex", gap: "11px" }}
                  >
                    <div className="text" style={{ fontSize: "sans-serif" }}>
                      <h3
                        style={{
                          color: "#000",
                          fontWeight: "bold",
                          
                        }}
                      >
                      {(aboutData.title1) && aboutData.title1 }
                      </h3>
                      <h6 style={{
                      color: "#838383",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}>
                      {(aboutData.description1) && aboutData.description1 }
                      </h6>
                    </div>
                     
                  </div>)
                }

              {(aboutData.title3)  &&
                  ( <>
                <br />
                <br />
                <div
                  className="wow fadeInUp"
                  data-wow-delay=".2s"
                  style={{ display: "flex", gap: "11px", marginLeft: "-3rem" }}
                >
                  <div className="text" style={{fontFamily:'sans-serif'}}>
                    <h3
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                       
                      }}
                    >
                      {(aboutData.title3) && aboutData.title3 }
                    </h3>
                    <h6 style={{
                      color: "#838383",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}>
                    {(aboutData.description3) && aboutData.description3}
                    </h6>
                  </div>
                   
                </div>
              
              </> 
              )
              }
              </div>

              <div className="box2" style={{ textAlign: "center" }}>
                {(aboutData.image) &&
                (<img
                  src={process.env.REACT_APP_IMAGE_URL+'/'+aboutData.image}                
                  height="300px"
                  width="300px"
                  alt=""
                />)
                } 
              </div>

              <div className="box3">
              {(aboutData.title2)  &&
                  ( <>
                  <div
                    className="wow fadeInUp"
                    data-wow-delay=".2s"
                    style={{display: 'flex', gap: '11px'}}
                  >
                     
                  <div className="text" style={{fontFamily: 'sans-serif'}}>
                     <h3 style={{color: '#000',fontWeight:'bold'}}>{(aboutData.title2) && aboutData.title2 }</h3>
                    <h6 style={{
                      color: "#838383",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}> 
                    {(aboutData.description2) && aboutData.description2}
                    </h6>
                    </div>
                  </div>
                  </>)
              }   


                {(aboutData.title4)  &&
                (<>
                    <br /><br />
                    <div className="wow fadeInRight" style={{display: 'flex',gap: '11px',marginLeft:'3rem'}}>
                       
                      <div className="text" style={{fontFamily: 'sans-serif'}}>
                        <h3 style={{color:'#000',fontWeight:'bold'}}>{(aboutData.title4) && aboutData.title4 }</h3>
                      <h6 style={{
                      color: "#838383",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}>
                      {(aboutData.description4) && aboutData.description4}
                      </h6>
                      </div>
                    </div>
                </>)}
                  
                </div>  
               


            </div>
          </div>
        </div>
      </section>
    
    </>
  )
}
export default About;