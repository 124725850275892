import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Client } from "../../api/Client";
import { Endpoint } from "../../Events/Endpoint";
import { isEmptyArray } from "../../Utility/Utility";

function OnlineCourses(props) {
  const [coursesData, setCourses] = useState([]);
  const [coursesDetailsData, setCoursesDetails] = useState([]);
  const [coursesVideo, setCoursesVideo] = useState(null);

  useEffect(() => {
    getCourse();
  }, []);

  function getCourse() {
    const url = Endpoint.ONLINE_COURSE_LIST;
    Client.getWithLoader(
      url,
      (response) => {
        if (!isEmptyArray(response.data)) {
          if (response.data.status) {
            setCourses(response.data.data);

            if (!isEmptyArray(props.dataProp)) {
              console.log("dataProp: ", props.dataProp.id);
              setCoursesDetails(props.dataProp.id);
            } else {
              !isEmptyArray(response.data.data[0]) &&
                setCoursesDetails(response.data.data[0].id);
            }

            /*
            (!isEmptyArray(response.data.data[0]) &&  setCoursesDetails(response.data.data[0].id) );
            */
          }
        }
      },
      (error) => {
        setCourses([]);
      }
    );
  }

  return (
    <>
      <section
        id="online-courses"
        className="de_light"
        style={{ backgroundColor: "#ffffff" }}
      >
        <div className="wm">Courses</div>

        <div className="container">
          <div className="row" id="online-courses-tab">
            <div className="col-md-6 offset-md-3 text-center wow fadeInUp">
              <h5 className="s2">How It Works</h5>
              <h1>Online Courses</h1>
              <div className="separator">
                <span>
                  <i className="fa fa-square"></i>
                </span>
              </div>
              <div className="spacer-single"></div>
            </div>

            <div className="col-md-12">
              <div className="de_tab tab_steps square dark">
                <ul className="de_nav">
                  {!isEmptyArray(coursesData) &&
                    coursesData.map((data, key) => (
                      <li
                        key={key}
                        className={
                          "wow fadeIn " +
                          (data.id == coursesDetailsData
                            ? "animated active"
                            : "")
                        }
                        data-wow-delay="0s"
                        onClick={() => {
                          setCoursesDetails(data.id);
                        }}
                      >
                        <span>{data.title}</span>
                        <div className="v-border"></div>
                      </li>
                    ))}
                </ul>

                {!isEmptyArray(coursesDetailsData) && (
                  <div className="de_tab_content">
                    {coursesData.map((data, key) => (
                      <div
                        key={key}
                        className={
                          data.id == coursesDetailsData
                            ? "active"
                            : "hideCourse"
                        }
                      >
                        {data.description}

                        <div className="row">
                          <div className="col-md-12">
                            {" "}
                            <h4> Demo video </h4>{" "}
                          </div>
                        </div>

                        <div className="row">
                          {data.short_videos.map((video, key) => (
                            <div
                              key={key}
                              className={
                                "col-md-4 " +
                                (video.video_id == coursesVideo
                                  ? " current-active "
                                  : "")
                              }
                              onClick={() => {
                                setCoursesVideo(video.video_id);
                              }}
                            >
                              <video
                                playsInline=""
                                autoPlay=""
                                muted=""
                                loop=""
                                width="100%"
                                height="100%"
                                src={
                                  process.env.REACT_APP_VIDEO_URL +
                                  "/" +
                                  video.short_video
                                }
                                type="video/mp4"
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {!isEmptyArray(coursesDetailsData) && (
        <section
          id="view-all-projects"
          className="de_light call-to-action bg-color padding40"
          data-speed="5"
          data-type="background"
          aria-label="cta"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h3 className="size-2">Download full video</h3>
              </div>
              <div className="col-md-4 text-right">
                {coursesVideo != null ? (
                  <Link
                    to={`/checkout/course/${coursesDetailsData}/${coursesVideo}`}
                    className="btn-line black btn-big wow fadeInUp animated"
                  >
                    Buy Now{" "}
                  </Link>
                ) : (
                  <a className="btn-line black btn-big wow fadeInUp animated">
                    Select Video To Buy
                  </a>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
export default OnlineCourses;
