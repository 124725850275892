import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import React, { useEffect, useState } from 'react'
import { Endpoint } from '../Events/Endpoint';
import { Client } from '../api/Client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { calculateTax, getCartCount, getCartProducts, getSubTotal, getTotalAmount, removeCartItem } from '../Features/useCartSlice';
import { isEmptyArray } from '../Utility/Utility';
import StringUtils from '../Utility/StringUtils';

function CartCheckoutPaypal(props) {

 

    const location = useLocation();

    const [propsData, setPropsData] = useState((location.state) ? location.state : []);

    const [successStatus, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errMessage, setError] = useState(""); 
    const navigate = useNavigate(); 

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const dispatch = useDispatch();
    const { cartItems, subAmount, tax, totalAmount } = useSelector(
        (state) => state.cart
    );

    useEffect(() => {
        dispatch(getCartProducts());
        dispatch(getSubTotal());
        dispatch(getCartCount());
        dispatch(calculateTax());
        dispatch(getTotalAmount());
    }, [dispatch]);


    
    useEffect(() => {
        window.scrollTo(0, 0);  
        
        if(propsData){
          console.log("propsData: ",propsData);

           if(isEmptyArray(propsData)){
             
            navigate('/cart');

           }

        }
        
        
    }, []);   
 


  return (
    <>

<div className="mt70 sm-mt0">
        <section style={{ backgroundColor: "#202124" }}>
          <div className="text-center">
            <h1>Checkout</h1>
          </div>
        </section>
 
         

        <section className="pt-5 pb-5 de_light">
          <div className="container">
            <div className="row">
              {successStatus ? (
                <>
                  <h3 className="col-sm-12 text-center success">
                    {successMessage}
                  </h3>
                </>
              ) : (
                <>

                   <br/> 
                   <br/> 
                   <br/> 

                  <div className="col-md-5 order-md-2 mb-4 mt-5 ">
                    <h4 className="d-flex justify-content-between align-items-center mb-3">
                      <span className="text-muted"></span>
                    </h4>
 

                    <div className="  ">
                      <div className="row mb-4">
                        <div className="col-sm-12 ">
                          <h5 className="mb-3">Your order</h5>

                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th colSpan={2}>Product</th>
                                <th className="col-sm-3 col-md-3 ">Subtotal</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cartItems.map((product, index) => {
                                return (
                                  <tr key={product.id}>
                                    <td className="col-sm-1 col-md-1 ">
                                      <img
                                        className="media-object"
                                        style={{ width: "90px" }}
                                        src={process.env.REACT_APP_IMAGE_URL + '/' +product.image}
                                        alt={product.name}
                                      />
                                    </td>
                                    <td className="col-sm-3 col-md-3 ">
                                      {product.name} * {product.quantity}
                                    </td>
                                    <td className="col-sm-3 col-md-3 ">
                                      <strong>{process.env.REACT_APP_PRICE_SYMBOL+' '+parseFloat(
                                          product.price * product.quantity
                                        ).toFixed(2)}
                                      </strong>
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td colSpan={2} className={"text-right"}>
                                  Subtotal
                                </td>
                                <td>{process.env.REACT_APP_PRICE_SYMBOL+' '+parseFloat(subAmount).toFixed(2)}</td>
                              </tr>
                              <tr>
                                <td colSpan={2} className={"text-right"}>
                                  Total Amount
                                </td>
                                <td>{process.env.REACT_APP_PRICE_SYMBOL+' '+parseFloat(totalAmount).toFixed(2)}</td>
                              </tr>
                            </tbody>
                          </table>
 
                        </div>
                      </div>
                    </div>
                 
                    
                       
                  <PayPalScriptProvider options={{ clientId: "AXkCiPJXPwfa_kHFm4WtbGI3m_FOhJL4SMzCXiEHLyCwLIVtVpZM0YrSqrAfHM1CCCxq4qWOS5c_yZo0", intent: "capture",currency: "USD" }}>
            <PayPalButtons 
                style={{ layout: "horizontal" }} 
                
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: parseFloat((totalAmount/(45.40))).toFixed(2),
                                },
                            },
                        ],
                    });
                }}


                onApprove={(data, actions) => {
                    return actions.order.capture().then((paypalResponseDetails) => {
                       let url = Endpoint.PAYPAL_SHOP_PLACE_ORDER;
                       
                              /* const name = details.payer.name.given_name;  
                              let inputJSON = [];
                              inputJSON.paypalResponseDetails = details; */

                              paypalResponseDetails.inputJSON = propsData;

                            Client.postWithLoader(
                                url,
                                paypalResponseDetails,
                                true,
                                (response) => {

                                  if (StringUtils.isNotEmpty(response.data.status)) {
                                    if (response.data.status == true) {
                                      setSuccess(true);
                                      setSuccessMessage(response.data.message);
                        
                                      cartItems.map((product, index) => {
                                        dispatch(removeCartItem(product.id))
                                          dispatch(getSubTotal())
                                          dispatch(getCartCount())
                                          dispatch(calculateTax())
                                          dispatch(getTotalAmount())
                                        });
                        
                                    } else {
                                      setError(response.data.message);
                                      setTimeout(() => {
                                        setError("");
                                      }, 3000);
                                    }
                                  }

                                

                                },
                                (error) => {
                                
                                  setSuccess(false);

                                }
                            );
                            



                    });
                }}

                />
        </PayPalScriptProvider>

                      <div className="col-md-12">
                        {errMessage && (
                          <div id="mail_fail" className="error">
                            {errMessage}
                          </div>
                        )}
                      </div>
                   
                  </div>
                </>
              )}
            </div>
          </div>
        </section>


        </div>
    </>
  )
}

export default CartCheckoutPaypal;