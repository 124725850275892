import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import { Client } from '../api/Client';
import { Endpoint } from '../Events/Endpoint';
import { isEmptyArray, isNotEmpty } from '../Utility/Utility';
import ProductItem from '../components/shop/ProductItem';
import ShopSidebar from '../components/shop/ShopSidebar';

function Shop() {
  let location = useLocation(); 
  const { slug } = useParams();

  const [pageData, setPageData] = useState([]);
  const [headerBgImage, setHeaderBgImage] = useState("");
  const [Product, setProduct] = useState([]);

  useEffect(() => {
    getPageData();  
    window.scrollTo(0, 0);
    
  }, []); 

  useEffect(() => {
   
    getProduct();
  }, [slug]); 


  function getPageData() {
    const url = Endpoint.PAGES_DATA+'/shop';
    Client.getWithLoader(
      url,
      (response) => {
         
        if (!isEmptyArray(response.data)) {
          if (response.data.status) { 
            setPageData(response.data.data); 

            isNotEmpty(response.data.data.header_background_image) && ( setHeaderBgImage(process.env.REACT_APP_IMAGE_URL + "/" +response.data.data.header_background_image) );

          }
        }
      },
      (error) => {
        setPageData([]);
        setHeaderBgImage("");
      }
    );
  }

  function getProduct() {

    let url = ""
    url= Endpoint.GET_PRODUCT_LISTING+`?page=1&per_page=12`;
    if(slug!="" && slug!== undefined){
      url= Endpoint.GET_PRODUCT_LISTING+"/"+slug+`?page=1&per_page=12`;
    }


    Client.getWithLoader(
      url,
      (response) => {
        setProduct(response.data.data);
      },
      (error) => {
        setProduct([]);
      }
    );
  }

  return (
    <>

<div className={(location.pathname=='/shop' || location.pathname.includes('shop') ) && 'de_light' } > 
    
<section
          id="subheader"
          style={{
            background:
              'url("'+headerBgImage+'") 0% 0px / cover',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>{(pageData) ? pageData.header_title : 'Shop'}</h1>
                <ul className="crumb">
                  <li><Link to="/" >Home</Link></li>
                  <li className="sep">/</li>
                  <li>{(pageData) ? pageData.title : 'Shop'}</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
    


      <div id="content">
        <div className="container">
          <div className="row">
            <div className="col-md-9">

            {!isEmptyArray(Product) && (
              <ul className="products row">  

                {Product.map((value, key) => (
                    <ProductItem data={value} key={key} />
                  ))} 

                 
              </ul>
               )}
            </div>


             <ShopSidebar /> 
            

          </div>
        </div>
      </div>

      </div>
    </>
  )
}
export default Shop;