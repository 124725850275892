import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Client } from '../api/Client';
import AboutTeam from '../components/about/AboutTeam';
import Testimonials from '../components/home/Testimonials';
import { Endpoint } from '../Events/Endpoint';
import { isEmptyArray, isNotEmpty } from '../Utility/Utility';

function About() {
  const [pageData, setPageData] = useState([]);
  const [headerBgImage, setHeaderBgImage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getPageData();  
  }, []); 


  function getPageData() {
    const url = Endpoint.PAGES_DATA+'/about_us';
    Client.getWithLoader(
      url,
      (response) => {
         
        if (!isEmptyArray(response.data)) {
          if (response.data.status) { 
            setPageData(response.data.data); 

            isNotEmpty(response.data.data.header_background_image) && ( setHeaderBgImage(process.env.REACT_APP_IMAGE_URL + "/" +response.data.data.header_background_image) );

          }
        }
      },
      (error) => {
        setPageData([]);
        setHeaderBgImage("");
      }
    );
  }

  return (
    <>
    
    <div className="page-about"> 
      

        <section id="subheader" className='subheader-bg-color'   style={{backgroundImage: 'url("'+headerBgImage+'")', backgroundRepeat: "no-repeat"}} >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>{(pageData) ? pageData.header_title : 'About Us'}</h1>
                <ul className="crumb">
                  <li><Link to="/" >Home</Link></li>
                  <li className="sep">/</li>
                  <li>{(pageData) ? pageData.title : 'About Us'}</li>
                </ul>
              </div>
            </div>
          </div>
        </section> 


        <div id="content" className="no-top no-bottom">

          {(pageData) &&(<>
          
            <section id="section-about-us-2" className="side-bg no-padding">

             {(pageData.image) && (<>
              <div className="image-container col-md-5 pull-left" data-delay="0" style={{backgroundImage: 'url('+process.env.REACT_APP_IMAGE_URL + '/' +pageData.image+')'}}></div>
             </>)} 
            

            <div className="container">
            <div className="row">
              <div className="inner-padding">
              <div
                className="col-md-6 offset-md-6"
                data-animation="fadeInRight"
                data-delay="200"
              > 

                <div dangerouslySetInnerHTML={{ __html: pageData.description }}></div>

              </div>
              <div className="clearfix"></div>
              </div>
            </div>
            </div>
          </section>
          
          </>)}
          

          
          <AboutTeam />  
         

          <Testimonials />



        </div>
</div>	 



    </>
  )
}
export default About;