import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Endpoint } from "../Events/Endpoint";
import { Client } from "../api/Client";
import { isEmptyArray } from "../Utility/Utility";

export default function Footer() {
  const [contactDetails, setContactDetails] = useState([]);

  useEffect(() => { 
    getContactDetails();  
  }, []) 

  function getContactDetails() {
    const url = Endpoint.CONTACT_DETAILS;;
    Client.getWithLoader(
      url,
      (response) => { 
      if (!isEmptyArray(response.data)) {
        if (response.data.status) {

          console.log(response.data.data);

          setContactDetails(response.data.data);
        }
      }
      },
      (error) => {
        setContactDetails([]);
      }
    );
    }




  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-3 ">
              <div className="widget widget_recent_post">
                <h3>Shop</h3>
                <ul>
                  <li>
                    <Link to="/shop/women">Women</Link>
                  </li>
                  <li>
                    <Link to="/shop/men">Men </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-3 offset-md-1">
              <div className="widget widget_recent_post">
                <h3>Help</h3>
                <ul>
                  <li>
                    <a href="#">Shipping Policy</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-4 offset-md-1">
              <h3>Contact Us</h3>
              <div className="widget widget-address">
                <address>
                  <span>{(contactDetails.Address) && contactDetails.Address}</span>
                  <span>
                    <strong>Phone:</strong>{(contactDetails.PhoneNumber) && contactDetails.PhoneNumber}
                  </span>
                
                  <span>
                    <strong>Email:</strong>
                    <a href="mailto:{(contactDetails.EmailAddress) && contactDetails.EmailAddress}">{(contactDetails.EmailAddress) && contactDetails.EmailAddress}</a>
                  </span>
                   
                </address>
              </div>
            </div>
          </div>
        </div>

        <div className="subfooter">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                © Copyright 2023 -{" "}
                <span style={{ color: "#FAB702" }}>Impulsion Team</span>{" "}
               
              </div>
              <div className="col-md-6 text-right">
                <div className="social-icons">
                  <a href="#">
                    <i className="fa fa-facebook fa-lg"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-twitter fa-lg"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-rss fa-lg"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-google-plus fa-lg"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-skype fa-lg"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-dribbble fa-lg"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a href="#" id="back-to-top"></a>
      </footer>
    </>
  );
}
