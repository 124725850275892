export const HOST = process.env.REACT_APP_API_URL;
export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;


export const Endpoint = { 
    
    HOME_SLIDER_LIST: HOST + "/home/sliders",
    HOME_ABOUT_DATA: HOST + "/home/about_us",
    TESTIMONIAL_LIST: HOST + "/testimonials",
    COURSE_CATEGORY: HOST + "/course/category",
    COURSE:  HOST + "/course",
    COURSE_FEATURED:  HOST + "/course_with_featured",

    ONLINE_COURSE_LIST:  HOST + "/online/course",  
    SUBMIT_CONTACT_FORM: HOST + "/contact/submit",    

    GALLERY_CATEGORY_LIST: HOST + "/gallery/category",
    GALLERY_LIST: HOST + "/gallery",
    TEAM_LIST: HOST + "/team",
    CONTACT_INFO_LIST: HOST + "/contact/infolist", 

    CONTACT_DETAILS: HOST + "/contact/details", 

    COURSE_PLACE_ORDER: HOST + "/course/order/place_order", 

    SHOP_PLACE_ORDER: HOST + "/shop/order/place_order", 
    
    PAGES_DATA: HOST + "/pages", 

    GET_PRODUCT_LISTING: HOST + "/shop",

    PAYPAL_SHOP_PLACE_ORDER: HOST + "/shop/order/paypal/place_order", 
    PAYPAL_COURSE_PLACE_ORDER: HOST + "/course/order/paypal/place_order",
    
    PAYMENT_DETAILS: HOST + "/payment/list",

}