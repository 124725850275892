import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Navigation() {
  const dispatch = useDispatch();
  const { totalCount, cartItems, totalAmount } = useSelector(
    (state) => state.cart
  );

  return (
    <>
      <li>
        <Link className="" to="/">
          Home<span></span>
        </Link>
      </li>
      <li>
        <Link to="/about">About us</Link>
      </li>
      <li>
        <Link to="/courses">Courses</Link>
      </li>
      <li>
        <Link to="/shop">Shop</Link>
        <ul>
          <li>
            <Link to="/shop/women">Women</Link>
          </li>
          <li>
            <Link to="/shop/men">Men </Link>
          </li>
        </ul>
      </li>
      <li>
        <Link to="/team">Team</Link>
      </li>
      <li>
        <Link to="/gallery">Gallery</Link>
      </li>
      <li>
        <Link to="/contact">Contact us</Link>
      </li>

      <li className="cart-li">
        <Link to="/cart">
          <i className="fa">&#xf07a;</i>
          <span className="badge badge-warning">{totalCount}</span>
        </Link>
      </li>
    </>
  );
}
