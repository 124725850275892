import { BraintreePayPalButtons, PayPalButtons, PayPalHostedField, PayPalHostedFieldsProvider, PayPalScriptProvider, usePayPalHostedFields } from '@paypal/react-paypal-js';
import React, { useEffect, useRef, useState } from 'react'
import { Endpoint } from '../Events/Endpoint';
import { Client } from '../api/Client';



function TestPage() {

  
  return (
    <>
    

        <p>---------------------------------</p>
        <p>---------------------------------</p>
        <br/>
        <br/>

        <PayPalScriptProvider options={{ clientId: "AXkCiPJXPwfa_kHFm4WtbGI3m_FOhJL4SMzCXiEHLyCwLIVtVpZM0YrSqrAfHM1CCCxq4qWOS5c_yZo0", intent: "capture",currency: "USD" }}>
            <PayPalButtons 
                style={{ layout: "horizontal" }} 
                
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: "1.00",
                                },
                            },
                        ],
                    });
                }}


                onApprove={(data, actions) => {
                    return actions.order.capture().then((paypalResponseDetails) => {
                       let url = Endpoint.PAYPAL_SHOP_PLACE_ORDER;
                       
                              /* const name = details.payer.name.given_name;  
                              let inputJSON = [];
                              inputJSON.paypalResponseDetails = details; */

                            Client.postWithLoader(
                                url,
                                paypalResponseDetails,
                                true,
                                (response) => {

                                

                                },
                                (error) => {
                                
                                }
                            );
                            



                    });
                }}

                />
        </PayPalScriptProvider>

        <br/>
        <br/>
        <p>---------------------------------</p>
        <p>---------------------------------</p>


    
    </>
  )
}
export default TestPage;